import * as React from "react";

function YoutubeSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={97.301}
      height={21.699}
      {...props}
    >
      <defs>
        <style>{".prefix__b{fill:#fff}"}</style>
      </defs>
      <path
        d="M30.354 3.4A3.917 3.917 0 0027.622.638C25.2 0 15.5 0 15.5 0S5.8 0 3.37.638A3.873 3.873 0 00.638 3.4 40.915 40.915 0 000 10.85a40.763 40.763 0 00.638 7.45A3.863 3.863 0 003.4 21.061c2.4.638 12.1.638 12.1.638s9.7 0 12.126-.638a3.883 3.883 0 002.732-2.732 40.915 40.915 0 00.638-7.454 41.143 41.143 0 00-.642-7.475z"
        fill="red"
      />
      <path
        className="prefix__b"
        d="M12.407 15.495l8.067-4.646-8.067-4.646zM45.007 19.759a3.207 3.207 0 01-1.3-1.94 13.292 13.292 0 01-.383-3.472v-1.965a11.722 11.722 0 01.46-3.523 3.56 3.56 0 011.379-1.94 4.639 4.639 0 012.476-.613 4.323 4.323 0 012.425.613 3.568 3.568 0 011.327 1.94 11.686 11.686 0 01.408 3.523v1.966a12.489 12.489 0 01-.408 3.5 3.439 3.439 0 01-1.327 1.94 4.479 4.479 0 01-2.5.613 4.823 4.823 0 01-2.557-.642zm3.446-2.147a6.372 6.372 0 00.255-2.17v-4.209a6.4 6.4 0 00-.255-2.144.927.927 0 00-.893-.689.9.9 0 00-.868.689 6.4 6.4 0 00-.255 2.144v4.238a6.372 6.372 0 00.255 2.17.858.858 0 00.894.664.92.92 0 00.867-.693zM92.133 14.373v.689c0 .664.026 1.3.077 1.966a1.836 1.836 0 00.332.945.918.918 0 00.766.306.988.988 0 00.945-.536 4.632 4.632 0 00.281-1.761l2.629.153a3.1 3.1 0 01.025.485 3.573 3.573 0 01-1.021 2.808 4.234 4.234 0 01-2.91.919 3.48 3.48 0 01-3.166-1.43 8.214 8.214 0 01-.919-4.391v-2.374a8.135 8.135 0 01.945-4.472 3.589 3.589 0 013.217-1.4 4.305 4.305 0 012.425.587 3.209 3.209 0 011.2 1.787 12.49 12.49 0 01.332 3.37v2.323zm.383-5.718a1.8 1.8 0 00-.306.945q-.076 1-.077 1.991v.97h2.247v-.97a17.351 17.351 0 00-.1-1.991 1.836 1.836 0 00-.332-.945.858.858 0 00-.715-.281.83.83 0 00-.717.28zM37.553 14.118L34.081 1.532h3.038l1.225 5.693q.46 2.106.689 3.6h.1c.1-.715.332-1.889.689-3.574l1.254-5.719h3.038l-3.523 12.586v6.05h-3.038zM61.68 6.561v13.607h-2.4l-.255-1.659h-.077a3.142 3.142 0 01-2.936 1.889 2.322 2.322 0 01-1.991-.894 4.714 4.714 0 01-.638-2.783V6.561h3.063v9.982a3.241 3.241 0 00.2 1.3.672.672 0 00.664.383 1.547 1.547 0 001.3-.868V6.561zM77.381 6.561v13.607h-2.4l-.255-1.659h-.077a3.142 3.142 0 01-2.936 1.889 2.322 2.322 0 01-1.991-.894 4.714 4.714 0 01-.638-2.783V6.561h3.064v9.982a3.241 3.241 0 00.2 1.3.672.672 0 00.664.383 1.547 1.547 0 001.3-.868V6.561z"
      />
      <path
        className="prefix__b"
        d="M70 4.008h-3.038v16.16h-2.987V4.008h-3.038V1.532H70zM87.515 8.73a3.54 3.54 0 00-.894-1.864 2.5 2.5 0 00-1.71-.587 2.752 2.752 0 00-1.583.485 3.122 3.122 0 00-1.123 1.251h-.026V.842h-2.964v19.325h2.527l.306-1.276h.077a2.613 2.613 0 001.072 1.1 3.115 3.115 0 001.583.408 2.468 2.468 0 002.3-1.43 10.31 10.31 0 00.74-4.468v-2.146a21.049 21.049 0 00-.305-3.625zm-2.808 5.54a17.716 17.716 0 01-.128 2.323 2.5 2.5 0 01-.408 1.2.957.957 0 01-.766.357 1.264 1.264 0 01-.689-.179 1.474 1.474 0 01-.511-.536v-7.71a1.872 1.872 0 01.536-.919 1.1 1.1 0 01.817-.357.86.86 0 01.715.357 2.957 2.957 0 01.357 1.225c.077.817.1 1.634.1 2.451z"
      />
    </svg>
  );
}

export default YoutubeSvg;

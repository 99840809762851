import Scale from "../../assets/groupScale.svg";
import Store from "../../assets/groupStore.svg";
import Setting from "../../assets/groupSetting.svg";
import webIcon from "../../assets/webIcon.svg";
import Business from "assets/business.svg";
import Medical from "assets/medical.svg";
import LegalContract from "assets/LegalContractTranslationICON.svg";
import LegalSworn from "assets/SwornAndCertifiedICON.svg";
import LegalCourt from "assets/courtInterpretingICON.svg";
import LegalDoc from "assets/legalDocConsultancyICON.svg";
import LegalCompliance from "assets/intComplianceICON.svg";
import LegalAffidavits from "assets/translationAffidavitsICON.svg";

export const data = [
  {
    id: 1,
    image: Scale,
    text: "Legal",
  },
  {
    id: 2,
    image: Store,
    text: "Retail",
  },
  {
    id: 3,
    image: Setting,
    text: "Manufacturing",
  },
];

export const DocumentData = [
  {
    id: 1,
    image: Medical,
    text: "Manufacturing",
  },
  {
    id: 2,
    image: Scale,
    text: "Legal",
  },
  {
    id: 3,
    image: Business,
    text: "Retail",
  },
];

export const LegalDocumentData = [
  {
    id: 1,
    image: LegalContract,
    text: "Legal Contract Translation Services",
  },
  {
    id: 2,
    image: LegalSworn,
    text: "Sworn or Certified Translations",
  },
  {
    id: 3,
    image: LegalCourt,
    text: "Court Interpreting",
  },
  {
    id: 4,
    image: LegalDoc,
    text: "Legal Document Consultancy",
  },
  {
    id: 5,
    image: LegalCompliance,
    text: "International Compliance Consultancy",
  },
  {
    id: 6,
    image: LegalAffidavits,
    text: "Translation Affidavits",
  },
];

export const languages = [
  {
    id: 1,
    hrefLink: "",
    text: "Spanish",
  },
  {
    id: 2,
    hrefLink: "",
    text: "Italian",
  },
  {
    id: 3,
    hrefLink: "",
    text: "French",
  },
  {
    id: 4,
    hrefLink: "",
    text: "Arabic",
  },
  {
    id: 5,
    hrefLink: "",
    text: "Chinese",
  },
  {
    id: 6,
    hrefLink: "",
    text: "Korean",
  },
  {
    id: 7,
    hrefLink: "",
    text: "German",
  },
  {
    id: 8,
    hrefLink: "",
    text: "Japanese",
  },
  {
    id: 9,
    hrefLink: "",
    text: "Swedish",
  },
  {
    id: 10,
    hrefLink: "",
    text: "Russian",
  },
];
export const ServicesData = [
  {
    id: 1,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Bank Statements",
  },
  {
    id: 2,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Medical Records",
  },
  {
    id: 3,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Journal Articles",
  },
  {
    id: 4,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Public Documents",
  },
  {
    id: 5,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Marketing Copy",
  },
  {
    id: 6,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Websites and Apps",
  },
];

export const ServicesData2 = [
  {
    id: 1,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Bank Statements",
  },
  {
    id: 2,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Journal Articles",
  },
  {
    id: 3,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Marketing Copy",
  },
];

export const DocData = [
  {
    id: 1,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Apostilles",
  },
  {
    id: 2,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Birth / Marriage Certificates",
  },
  {
    id: 3,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Diplomas",
  },
  {
    id: 4,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "License Agreements",
  },
  {
    id: 5,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Patents",
  },
  {
    id: 6,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Technical Reports",
  },
];

export const DocData2 = [
  {
    id: 1,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Apostilles",
  },
  {
    id: 2,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Birth / Marriage Certificates",
  },
  {
    id: 3,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Diplomas",
  },
];

export const LegalDocData = [
  {
    id: 1,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Apostilles",
  },
  {
    id: 2,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Birth / Marriage Certificates",
  },
  {
    id: 3,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Diplomas",
  },
  {
    id: 4,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "License Agreements",
  },
  {
    id: 5,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Patents",
  },
  {
    id: 6,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Contracts",
  },
];

export const LegalData2 = [
  {
    id: 1,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Apostilles",
  },
  {
    id: 2,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Birth / Marriage Certificates",
  },
  {
    id: 3,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Diplomas",
  },
];
